@import "variables.scss";
@import "mixins.scss";
@import "global.scss";
@import "navbar.scss";
@import "masthead.scss";
@import "download.scss";
@import "features.scss";
@import "cta.scss";
@import "contact.scss";
@import "footer.scss";
@import "card-container.scss";
@import "bootstrap-overrides.scss";
.board-display {
    display: grid;
    grid-template-columns: 80px 80px 240px 80px 80px;
    grid-template-rows: 80px 80px 240px 80px 80px;
    gap: 4px;
    >* {
        display: flex;
        align-items: center;
        position: relative;
        .board-deck {
            overflow: hidden;
            border-radius: 4.54545454545%/2.94117647059%;
            backface-visibility: hidden;
            border: 0px solid white;
            img {
                width: 109.09090%;
                margin: -4.54545454545%;
            }
            max-width: 40px;
            transform: translate(-50%, -50%);
            margin: 0;
            top: 50%;
            left: 50%;
            position: absolute;
            // box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
        }
        >h4 {
            transform: translate(-50%, -50%);
            min-width: 240px;
            text-align: center;
            margin: 0;
            top: 50%;
            left: 50%;
            position: absolute;
            .board-card {
                overflow: hidden;
                border-radius: 4.54545454545%/2.94117647059%;
                backface-visibility: hidden;
                border: 0px solid white;
                img {
                    width: 109.09090%;
                    margin: -4.54545454545%;
                }
                display: inline-block;
                max-width: 40px;
                margin: 0;
                // box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
            }
        }
    }
}