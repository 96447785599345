@keyframes spinning-card {
    0% {
        transform: translateZ(-200px) rotateY(0deg);
    }
    50% {
        transform: translateZ(-200px) rotateY(180deg);
    }
    100% {
        transform: translateZ(-200px) rotateY(360deg);
    }
}

.card-container {
    perspective: 50vh;
    width: 50vh;
    height: 50vh;
    position: relative;
    // background: radial-gradient(closest-side, white, black);
    border-radius: 50%;
    .card-mockup {
        transform-style: preserve-3d;
        transform: translate(-50%, -50%) translateZ(-20vh);
        // animation: infinite linear spinning-card 10s;
        position: absolute;
        top: 50%;
        left: 50%;
        // width: 220px;
        max-width: 100%;
        max-height: 100%;
        // height: 340px;
        // transition: linear transform 0.25s;
        // perspective: 600px;
    }
    img {
        width: 109.09090%;
        margin: -4.54545454545%;
    }
    .card-front {
        transform-style: preserve-3d;
        transform: translateZ(0px);
        // position: absolute;
        // top: 0%;
        // left: 0%;
        // width: 220px;
        // height: 340px;
        overflow: hidden;
        border-radius: 4.54545454545%/2.94117647059%;
        backface-visibility: hidden;
        border: 1px solid white;
    }
    .card-back {
        transform-style: preserve-3d;
        transform: translateZ(-0px) rotateY(180deg);
        position: absolute;
        top: 0%;
        left: 0%;
        // width: 220px;
        // height: 340px;
        overflow: hidden;
        border-radius: 4.54545454545%/2.94117647059%;
        backface-visibility: hidden;
        border: 1px solid white;
    }
}