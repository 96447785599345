// Styling for the download section
section.download {
    position: relative;
    padding: 50px 0;
    h2 {
        font-size: 50px;
        margin-top: 0;
    }
    .badges {
        .badge-link {
            display: block;
            margin-bottom: 25px;
            &:last-child {
                margin-bottom: 0;
            }
            img {
                height: 60px;
            }
            @media(min-width: 768px) {
                display: inline-block;
                margin-bottom: 0;
            }
        }
    }
    @media(min-width: 768px) {
        h2 {
            font-size: 70px;
        }
    }
}

.btn {
    .price {
        font-size: 2em;
        font-weight: 600;
    }
    .command {
        font-size: 2em;
        margin-left: 1em;
        font-weight: 100;
    }
}